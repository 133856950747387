@import 'src/styles/variables/variables';

.Root {
  @include min-width(desktop) {
    margin: 0 auto;
  }

  @include min-width(medium-desktop) {
    max-width: 1336px;
  }
}

.CardsWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;

  @include min-width(medium-tablet) {
    margin-left: unset;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: stretch;
    padding-bottom: 79px;

    > div:not(:first-child) {
      display: flex;
      background: #fff;
      z-index: 1;
    }
  }

  @include min-width(desktop) {
    padding-bottom: 104px;
  }

  @include min-width(medium-desktop) {
    padding-bottom: 80px;
  }
}

.sectionTitle {
  @include tertiaryFont(500);
  font-size: 14px;
  font-style: normal;
  line-height: 18px; /* 128.571% */
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;

  @include min-width(small-tablet) {
    margin-bottom: 40px;
  }

  @include min-width(desktop) {
    margin-bottom: 77px;
  }

  @include min-width(medium-desktop) {
    margin-bottom: 48px;
  }
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  @include min-width(medium-tablet) {
    align-items: flex-end;
  }


}

.Eyebrow {
  @include primaryFont(500);
  display: flex;
  align-self: center;
  text-align: center;
  margin-bottom: 10px !important;

  @include min-width(small-desktop) {
    margin-bottom: 27px !important;
  }

  @include min-width(xlarge-desktop) {
    margin-bottom: 55px !important;
  }
}

.sectionDesc {
  @include secondaryFont(400);
  font-size: 24px;
  font-style: normal;
  line-height: 30px;
  letter-spacing: -0.4px;
  display: block;
  max-width: 330px;
  margin: 0 0 20px;
  text-align: center;

  @include min-width(small-tablet) {
    max-width: 500px;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 38px;
  }

  @include min-width(small-desktop) {
    text-align: right;
    max-width: 630px;
    font-size: 34px;
    line-height: 38px;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
  }

  @include min-width(large-desktop) {
    max-width: 830px;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.4px;
    margin-bottom: 27px;
  }

  @include min-width(xlarge-desktop) {
    margin-bottom: 67px;
  }
}
