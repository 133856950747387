@import 'src/styles/variables/variables';

@include useTheme();

.Root {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 127px;
  height: 127px;
  color: $core2;
  overflow: hidden;
  padding: 17px;

  .HalfCircle {
    position: absolute;
    width: 127px * 2;
    height: 127px * 2;
    top: -127px;
    right: -127px;
    border-radius: 100%;
    background-color: $cinnamon;
    color: $core2;

  }

  @include min-width(small-tablet) {
    padding: 10px;

    .HalfCircle {
      top: -147px;
      right: -147px;
    }
  }

  @include min-width(medium-desktop) {
    padding: 17px;

    .HalfCircle {
      top: -127px;
      right: -127px;
    }
  }
}

.StampWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  position: relative;
}

@mixin stampStyle {
  @include tertiaryFont(500);
  font-size: 26.479px;
  font-style: normal;
  line-height: 29.127px;
  letter-spacing: 1.324px;
  text-transform: uppercase;
}


.Percent {
  @include stampStyle;
}

.Off {
  @include stampStyle;
}
